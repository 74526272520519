/* Fullscreen layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f3f4f6;
}

.sign-up-page {
  display: flex;
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

/* Left section, occupying 52% */
.left-section {
  flex: 0 0 52%; /* 52% width for the left side */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  height: 100vh;
  padding: 20px; /* Adds spacing around the image */
}

.holiday-image {
  width: 120%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
}

/* Right section with the sign-up form, occupying 48% */
.signup-section {
  flex: 0 0 48%; /* 48% width for the right side */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.logo {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  font-family: 'Georgia', serif;
  margin-bottom: 20px;
}

.form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Start from the top vertically */
  max-width: 400px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  margin: auto 0;
  margin-left: 130px
}

.form-container h1,
.form-container h2,
.form-container p,
.form-container button,
.form-container input {
  margin: 10px 0;
  width: 100%;
}

.signup-section h2 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.signup-buttons button {
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-btn i {
  margin-right: 8px;
}

/* Individual styling for each sign-up button */
.signup-btn.email {
  background-color: #1a73e8;
  color: white;
}

.signup-btn.email:hover {
  background-color: #1667c1;
}

.signup-btn.facebook {
  background-color: #4267B2;
  color: white;
}

.signup-btn.facebook:hover {
  background-color: #365899;
}

.signup-btn.google {
  background-color: #dbdbdb;
  color: #333;
}

.signup-btn.google:hover {
  background-color: #c7c7c7;
}

.login-prompt {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #555;
}

.login-prompt .link {
  color: #1a73e8;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.login-prompt .link:hover {
  text-decoration: underline;
}

.terms {
  font-size: 0.8rem;
  color: #777;
  margin-top: 1.5rem;
}

.terms a {
  color: #1a73e8;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sign-up-page {
    flex-direction: column;
  }

  .left-section, .signup-section {
    width: 100%;
    height: auto;
  }

  .holiday-image {
    max-height: 50vh;
  }

  .signup-section {
    padding: 20px;
  }
}
