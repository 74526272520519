/* Fullscreen layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f3f4f6;
}

.email-signup-page {
  display: flex;
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

/* Left section, occupying 52% */
.left-section {
  flex: 0 0 52%; /* 52% width for the left side */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  height: 100vh;
  padding: 20px; /* Adds spacing around the image */
}

.holiday-image {
  width: 120%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
}

/* Right section with the signup form, occupying 48% */
.signup-section {
  flex: 0 0 48%; /* 48% width for the right side */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center align text */
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.logo {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  font-family: 'Georgia', serif;
  margin-bottom: 20px;
}

.signup-section h2 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%; /* Ensure form takes full width */
  max-width: 300px; /* Optional: limit form width for a centered look */
}

.signup-form label {
  font-size: 0.9rem;
  color: #555;
  text-align: left;
  width: 100%; /* Full width of the form */
  margin-bottom: 5px;
}

.signup-form input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
  text-align: center; /* Center align input text */
}

.signup-form input:focus {
  border-color: #1a73e8;
  outline: none;
}

button[type="button"] {
  background-color: #1a73e8;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Full width for button within form */
}

button[type="button"]:hover {
  background-color: #1667c1;
}

p {
  font-size: 0.9rem;
  margin: 0.5rem 0;
  text-align: center;
}

p[style*="color: red"] {
  color: #d93025;
}

p[style*="color: green"] {
  color: #34a853;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .email-signup-page {
    flex-direction: column;
  }

  .left-section, .signup-section {
    width: 100%;
    height: auto;
  }

  .holiday-image {
    max-height: 50vh;
  }

  .signup-section {
    padding: 20px;
  }
}
