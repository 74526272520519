/* Fullscreen layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f3f4f6;
}

.login-page {
  display: flex;
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

/* .form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  max-width: 400px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  margin: auto 0;
  margin-left: 230px
} */

.form-container {
  position: fixed; /* Keeps the container in a fixed position */
  top: 50%; /* Centers it vertically */
  left: 75%; /* Centers within the right half */
  transform: translate(-80%, -50%); /* Adjusts to center based on its size */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px; /* Controls width to make it responsive */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}


.form-container h1,
.form-container h2,
.form-container p,
.form-container button,
.form-container input {
  margin: 10px 0;
  width: 100%;
}


/* Left section, occupying 60% */
.left-section {
  flex: 0 0 52%; /* 60% width for the left side */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  height: 100vh;
  padding: 20px; /* Optional: Adds spacing around the image */
}

.holiday-image {
  width: 120%; /* Set to fill the container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 100vh; /* Ensures the image fits within the viewport height */
  object-fit: contain; /* Ensures the entire image is visible without distortion */
}

/* Right section with the login options, occupying 40% */
.login-section {
  flex: 0 0 48%; /* 40% width for the right side */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.logo {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  font-family: 'Georgia', serif;
  margin-bottom: 20px;
}

.login-section h2 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.login-section p {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 2rem;
}

.login-buttons button {
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn.email {
  background-color: #1a73e8;
  color: white;
}

.login-btn.email:hover {
  background-color: #1667c1;
}

.login-btn.facebook {
  background-color: #4267B2;
  color: white;
}

.login-btn.facebook:hover {
  background-color: #365899;
}

.login-btn.google {
  background-color: #dbdbdb;
  color: #333;
}

.login-btn.google:hover {
  background-color: #c7c7c7;
}

.signup-prompt {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #555;
}

.signup-prompt .link {
  color: #1a73e8;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.signup-prompt .link:hover {
  text-decoration: underline;
}
