/* Fullscreen layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f3f4f6;
}

.email-login-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Left section with image, occupying 52% */
.left-section {
  flex: 0 0 52%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f3f4f6;
  /* border-right: 1px solid #e0e0e0; */
}

.holiday-image {
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
}

/* Right section with the login form, occupying 48% */
.login-section {
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center-align the form within the right section */
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  height: 100vh;
  text-align: left;
}

/* Logo styling */
.logo {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  font-family: 'Georgia', serif;
  margin-bottom: 20px;
}

/* Login section heading */
.login-section h2 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 0.5rem;
}

/* Login form styling */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
}

.login-form label {
  font-size: 0.9rem;
  color: #555;
  text-align: left;
}

.login-form input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.login-form input:focus {
  border-color: #4A707A;
  outline: none;
}

.login-form button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #1667c1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="button"]:hover {
  background-color: #1667c1;
}

.login-form p {
  font-size: 0.9rem;
  color: #333;
  margin-top: 10px;
  text-align: center;
}

.link {
  color: #4A707A;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .email-login-page {
    flex-direction: column;
  }

  .left-section, .login-section {
    width: 100%;
    height: auto;
  }

  .holiday-image {
    max-height: 50vh;
  }
  .login-section {
    padding: 20px;
  }
}
